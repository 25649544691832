<template>
  <div class="all-buildings">
    <AdminTable title="All Buildings" :list="buildingList" :fields="fields" :loading="loading"
    @update-list="update" @edit="editBuilding" @delete="deleteBuilding" @show-related-items="showBuildingRooms"
    @add-related-item="addRoom" @restore="restoreBuilding"/>
  </div>
</template>

<script>
import AdminTable from '@/components/admin/AdminTable'
import { buildingService } from '@/services/admin'

export default {
  name: 'AdminAllBuildings',
  components: {
    AdminTable,
  },
  data() {
    return {
      buildingList: [],
      loading: false,
    }
  },
  computed: {
    fields() {
      return [
        { key: 'id', label: 'ID' },
        { key: 'buildingName', label: 'Building' },
        { key: 'resType.name', label: 'Type' },
        { key: 'addressCity', label: 'City' },
        { key: 'addressStreet', label: 'Street'},
        { key: 'addressNumber', label: 'Number' },
        { key: 'addressZipCode', label: 'Zip Code'},
        { key: 'add', label: 'Add Room', type: 'actions', actions: ['add'] },
        { key: 'view', label: 'View Rooms', type: 'actions', actions: ['view'] },
        { key: 'actions', label: 'Actions', type: 'actions', actions: ['edit', 'delete'] },
      ]
    },
  },
  async beforeMount() {
    await this.update()
  },
  methods: {
    async update() {
      this.loading = true
      this.buildingList = await buildingService.getBuildings()
      this.loading = false
    },
    editBuilding(building) {
      this.$store.commit('adminBuildingStore/setBuilding', building)
      this.$store.commit('adminApartmentStore/setApartment', null)
      this.$router.push({ name: 'building-edit', query: { id: building.id }})
    },
    async deleteBuilding(building) {
      this.$store.commit('adminBuildingStore/setBuilding', null)
      const response = await buildingService.deleteBuilding(building.id)
      await this.update()
    },
    async restoreBuilding(building) {
      await buildingService.restoreBuilding(building.id)
      await this.update()
    },
    addRoom(building) {
      this.$store.commit('adminBuildingStore/setBuilding', building)
      this.$router.push({ name: 'room-add' })
    },
    showBuildingRooms(building) {
      this.$store.commit('adminBuildingStore/setBuilding', building)
      this.$router.push({ name: 'room-list' })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
